<template>
  <div class="companylibrary">
    <div class="companylibrary-left">
      <div class="companylibrary-left-header">
        <div class="item-box">
          <div class="header-title">组织类型:</div>
          <div class="items-main">
            <div
              v-for="item in companytype"
              :key="item.id"
              :class="
                item.conferenceCompanyTypeName == companytypetext
                  ? 'active-box'
                  : null
              "
              @click="changetype(item, '组织类型', 'conferenceCompanyTypeName')"
            >
              {{ item.conferenceCompanyTypeName }}
            </div>
          </div>
        </div>
        <!-- <div class="item-box">
          <div class="header-title">公司类型:</div>
          <div class="items-main">
            <div
              v-for="item in producttype"
              :class="item.conferenceCompanyTypeName == producttypetext ? 'active-box' : null"
              @click="changetype(item, 'producttypetext', 'name')"
            >
              {{ item.conferenceCompanyTypeName }}
            </div>
          </div>
          <div class="open" v-if="showflag" @click="show('showmore')">
            更多
            <i class="iconfont icon-xiajiantou"></i>
          </div>
          <div class="open" v-else @click="show('hidemore')">
            收起
            <i class="iconfont icon-arrow_up_fat"></i>
          </div>
        </div> -->
        <div class="item-box">
          <div class="header-title">公司类型:</div>
          <div class="items-main">
            <div
              v-for="item in producttype"
              :class="
                item.conferenceCompanyTypeName == producttypetext
                  ? 'active-box'
                  : null
              "
              @click="changetype(item, '公司类型', 'conferenceCompanyTypeName')"
            >
              {{ item.conferenceCompanyTypeName }}
            </div>
          </div>
          <div class="open" v-if="showflag" @click="show('showmore')">
            更多
            <i class="iconfont icon-xiajiantou"></i>
          </div>
          <div class="open" v-else @click="show('hidemore')">
            收起
            <i class="iconfont icon-arrow_up_fat"></i>
          </div>
        </div>
        <div class="item-box">
          <div class="header-title">公司性质：</div>
          <div class="items-main">
            <div
              v-for="item in Companycategory"
              :class="
                item.conferenceCompanyTypeName == Companycategorytext
                  ? 'active-box'
                  : null
              "
              @click="changetype(item, '公司性质', 'conferenceCompanyTypeName')"
            >
              {{ item.conferenceCompanyTypeName }}
            </div>
          </div>
          <div class="open" v-if="showXzflag" @click="showXz('showmore')">
            更多
            <i class="iconfont icon-xiajiantou"></i>
          </div>
          <div class="open" v-else @click="showXz('hidemore')">
            收起
            <i class="iconfont icon-arrow_up_fat"></i>
          </div>
        </div>
        <div class="item-box">
          <div class="header-title">省份地区：</div>
          <div class="items-main">
            <div
              v-for="item in Provincesregions"
              :class="
                item.conferenceCompanyTypeName == Provincesregionstext
                  ? 'active-box'
                  : null
              "
              @click="changetype(item, '省份地区', 'conferenceCompanyTypeName')"
            >
              {{ item.conferenceCompanyTypeName }}
            </div>
          </div>
          <div class="open" v-if="showsfflag" @click="showsf('showmore')">
            更多
            <i class="iconfont icon-xiajiantou"></i>
          </div>
          <div class="open" v-else @click="showsf('hidemore')">
            收起
            <i class="iconfont icon-arrow_up_fat"></i>
          </div>
        </div>
      </div>
      <div class="message-totle">共{{ total }}条</div>
      <div
        class="Company-details-item"
        v-for="item in CompanyLibrarylist"
        @click="goCompanyDetails(item)"
      >
        <div class="company-left">
          <img :src="item.logo" alt="" />
        </div>
        <div class="company-right">
          <div class="company_name">
            <p>{{ item.name }}</p>
            <div @click.stop="shoucang(item.conferenceCompanyId)">
              <span class="compant_shoucang" v-if="item.isFav">
                <img :src="imgIcon.imgCangTrue" />
                <span>已收藏</span>
              </span>
              <span class="compant_shoucang" v-else>
                <img :src="imgIcon.imgCang" />
                <span>收藏</span>
              </span>

              <!-- <span class="count">{{ item.read }}</span> -->
            </div>
          </div>

          <div class="type-box">
            <!-- <div class="type">品牌</div> -->
            <!-- <div class="type-item">{{ item.conferenceCompanyTypeName }}</div> -->
          </div>
          <div class="Sub-box">
            <span v-for="items in item.dt_Exhibits" class="Sub-label">{{
              items.name
            }}</span>
          </div>
          <p>{{ item.address }}</p>
          <p @click.stop="goUrl(item.website)">
            公司官网：<span>{{ item.website }}</span>
          </p>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="companylibrary-right">
      <div class="hot-demand">
        <div class="hot-demand-header">
          <i class="iconfont icon-gongsichaxun"></i>
          热门公司
        </div>
        <div
          class="hot-demand-item"
          v-for="(item, index) in hotdemandlist"
          @click="goCompanyDetails(item)"
        >
          <div class="item-left">
            <img :src="item.logo" alt="" />
          </div>
          <div class="item-mid">
            <p>{{ item.name }}</p>
            <div class="sub-box">
              <!-- <div>新材料</div> -->
              {{ item.address }}
            </div>
          </div>
          <!-- <div class="item-right"><div @click.stop="shoucang(item.conferenceCompanyId)">
                      <img :src="imgIcon.imgCang">
                    </div></div> -->
        </div>
      </div>
      <div class="group-box">
        <div class="group-header">
          <p class="header-title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            圈子
          </p>
          <!-- <div class="see-more">查看更多</div> -->
        </div>
        <div class="group-item">
          <div class="group-items" v-for="item in grouplist" @click="goCircleDetails(item)">
            <i class="iconfont icon-a-zu338"></i>
            <svg class="icon icon-a-zu337" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            <div class="title">
              <p>{{ item.title }}</p>
            </div>
            <!-- <div class="join"  @click="$store.commit('setVideoDialog', { show: true, url: item.linkUrl,title: item.title })"
            > -->
            <div
              class="join"
              
            >
              <i class="iconfont icon-a-zu340"></i>
              加入
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="follow-type">
        <div class="follow-type-header">
          <div class="join-people">
            <div :class="peopletype == '最新加入' ? 'active-people' : null">
              最新加入
            </div>
            <div :class="peopletype == '最受关注' ? 'active-people' : null">
              最受关注
            </div>
          </div>
          <div class="cheacd-all">查看全部</div>
        </div>
        <div class="follow-type-items" v-for="(item, index) in 10">
          <div class="items-img">
            <img src="@/assets/images/zjtx.png" alt="" />
          </div>
          <div class="user-introduce">
            <p>天福</p>
            <p>浙江帮里投资有限公司-销售</p>
          </div>
      
        </div>
      </div> -->
      <div class="join-Groupchat">
        <div>入驻公司库 / 反馈错误</div>
        <div class="ewmcode-box">
          <div class="ewmcode">
            <!-- rzgsk == 入驻公司库 -->
            <div class="rzgsk">
              <img src="@/assets/images/wxmpj.jpg" alt="" />
            </div>
            <img src="@/assets/images/ewmicon.png" alt="" />
            <br /><br />
            供需群
          </div>
          <div class="ewmcode">
            <!-- fkcw == 反馈错误 -->
            <div class="fkcw">
              <img src="@/assets/images/wxewm.png" alt="" />
            </div>
            <img src="@/assets/images/ewmicon.png" alt="" />
            <br /><br />
            联系人
          </div>
        </div>
      </div>
    </div>
    <video-dialog />
  </div>
</template>

<script>
import {
  getCompanyLibrarytype,
  getCompanyLibrarylistType,
  Grouplist,
  getProvinciallsit,
  getlabellist,
  getCompanyLibrarylist,
  getDTConferenceCompanyList,
  getDTConferenceCompanyFavList,
  getgklx,
  getgklb,
  getgkcplx,
  getDTConferenceCompanyTypeList,
  companyFav,
  companyFavDown,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "companylibrary",
  components: {
    videoDialog: () => import("@/components/Circle"),
  },
  data() {
    return {
      companytype: [],
      companytypetext: "全部",
      producttype: [],
      producttypetext: "全部",
      Companycategory: [],
      Companycategorytext: "全部",
      Provincesregions: [],
      Provincesregionstext: "全部",
      currentPage: 1,
      peopletype: "最新加入",
      Alllist: [],
      Provinciallsit: [],
      CompanyLibrarylist: [],
      grouplist: [],
      total: 0,
      typeId: [],
      citylist: [
        "北京",
        "天津",
        "上海",
        "广西壮族自治区",
        "重庆",
        "西藏自治区",
        "宁夏回族自治区",
        "新疆维吾尔自治区",
        "香港特别行政区",
        "澳门特别行政区",
      ],
      citylistresult: [],
      showflag: true,
      showXzflag: true,
      showsfflag: true,
      hotdemandlist: [],
      imgIcon: imgObj,
      companytypeSearch: "",
      producttypeSearch: "",
      CompanycategorySearch: "",
      ProvincesregionsSearch: "",
      myCompanylist: [],
      myQueryParams: {
        //列表搜索参数
        pageIndex: 1,
        pageSize: 10,
        conferenceId:7,
        userId: localStorage.getItem("userId") - 0,
      },
      myCompanyMyList: [],
    };
  },
  methods: {
    getMyCompanyList() {
      getDTConferenceCompanyFavList(this.myQueryParams).then((res) => {
        console.log("企业", res);
        this.myCompanyMyList = res.data.data.item1;
      });
    },
    goCircleDetails(item) {
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      let routeData = this.$router.resolve({
        name: "circleDetailsPage",
        query: {
          id: item.id|| item.Id,
          source: Number(item.source),
          title: item.title || item.Title,
          linkUrl:item.linkUrl 
        },
      });
      window.open(routeData.href, "_blank");
    },
    //组织类型
    getCompanyTypeZzlx() {
      getCompanyLibrarylistType(308).then((res) => {
        console.log("组织类型", res);
        this.companytype = res.data.data.reverse();
      });
    },
    //公司类型
    getCompanyTypeGslx() {
      getCompanyLibrarylistType(105).then((res) => {
        console.log("圈子分类", res);
        this.producttype = res.data.data.reverse().slice(0, 8);
      });
    },
    //公司性质
    getCompanyTypeGsxz() {
      getCompanyLibrarylistType(124).then((res) => {
        console.log("公司性质", res);
        this.Companycategory = res.data.data.reverse().slice(0, 7);
      });
    },
    //省份地区
    getCompanyTypeSfdq() {
      getCompanyLibrarylistType(135).then((res) => {
        console.log("省份地区", res);
        this.Provincesregions = res.data.data.reverse().slice(0, 9);
      });
    },
    async getgrouplist() {
      Grouplist().then((res) => {
        console.log("圈子标题", res);
        this.grouplist = res.data;
      });
    },
    showXz(type) {
      this.showXzflag = !this.showXzflag;
      if (type == "showmore") {
        getCompanyLibrarylistType(124).then((res) => {
          this.Companycategory = res.data.data.reverse();
        });
      } else {
        getCompanyLibrarylistType(124).then((res) => {
          this.Companycategory = res.data.data.reverse().slice(0, 7);
        });
      }
    },
    showsf(type) {
      this.showsfflag = !this.showsfflag;
      if (type == "showmore") {
        getCompanyLibrarylistType(135).then((res) => {
          console.log("省份地区", res);
          this.Provincesregions = res.data.data.reverse();
        });
      } else {
        getCompanyLibrarylistType(135).then((res) => {
          console.log("省份地区", res);
          this.Provincesregions = res.data.data.reverse().slice(0, 9);
        });
      }
    },
    show(type) {
      this.showflag = !this.showflag;
      if (type == "showmore") {
        getCompanyLibrarylistType(105).then((res) => {
          console.log("圈子分类", res);
          this.producttype = res.data.data.reverse();
        });

        // getDTConferenceCompanyTypeList().then((res) => {
        //   console.log(res);
        //   this.producttype = res.data.data;
        // });
        // getgkcplx().then((res) => {
        //   this.producttype = res.data.data.label;
        // });
      } else {
        getCompanyLibrarylistType(105).then((res) => {
          this.producttype = res.data.data.reverse().slice(0, 8);
        });
        // getDTConferenceCompanyTypeList().then((res) => {
        //   this.producttype = res.data.data.slice(0, 5);
        // });
        // getgkcplx().then((res) => {
        //   this.producttype = res.data.data.label.slice(0, 6);
        // });
      }
    },
    goCompanyDetails(item) {
      let routeData = this.$router.resolve({
        name: "companyDetail",
        query: {
          id: item.companyId,
          conferenceCompanyId: item.conferenceCompanyId,
          title: item.name,
        },
      });
      window.open(routeData.href, "_blank");
    },
    shoucang(articleId) {
      let username = window.localStorage.getItem("userName");
      let data = {
        userId: localStorage.getItem("userId") - 0,
        conferenceCompanyId: articleId,
      };
      if (username) {
        companyFav(data).then((res) => {
          if (res.data.code == "400") {
            companyFavDown(data).then((res) => {
              this.start();
            });
          } else {
            this.start();
          }
        });
      } else {
        this.$router.push('/login')
        this.$message({
          message: "您还没有登录，请先登陆才能收藏该文章",
          type: "warning",
        });
      }
    },
    goUrl(url) {
      window.open(`https://${url}`, "_blank");
    },
    changetype(item, name, type) {
      console.log(item, name);
      // this.typeId = item.conferenceCompanyTypeId;

      // this.handleCurrentChange(1,item.conferenceCompanyTypeId)
      if ([170, 171, 172, 173].includes(item.conferenceCompanyTypeId)) {
        this.typeId = 0;
      } else {
        this.typeId = item.conferenceCompanyTypeId;
        // this.typeId.push(item.conferenceCompanyTypeId)
      }
      switch (name) {
        case "组织类型":
          console.log("组织类型");
          // console.log(this);

          this.companytypeSearch = item.conferenceCompanyTypeId;
          this.companytypetext = item.conferenceCompanyTypeName;
          break;
        case "公司类型":
          console.log("公司类型");

          this.producttypeSearch = item.conferenceCompanyTypeId;
          this.producttypetext = item.conferenceCompanyTypeName;
          break;
        case "公司性质":
          console.log("公司性质");

          this.CompanycategorySearch = item.conferenceCompanyTypeId;
          this.Companycategorytext = item.conferenceCompanyTypeName;
          break;
        case "省份地区":
          console.log("省份地区");
          this.ProvincesregionsSearch = item.conferenceCompanyTypeId;
          this.Provincesregionstext = item.conferenceCompanyTypeName;
          break;
        default:
          console.log("都不是");
      }
      // this.typeId =[this.companytypeSearch,this.producttypeSearch,this.CompanycategorySearch,this.ProvincesregionsSearch]
      this.handleCurrentChange(1);
      this[name] = item[type];
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      let form = {
        pageIndex: this.currentPage,
        pageSize: 10,
        ConferenceId: 7,
        TypeId: this.typeId,
      };
      //列表
      getDTConferenceCompanyList(form).then((res) => {
        this.CompanyLibrarylist = res.data.data.form;
        // this.CompanyLibrarylist.classifyArr
        this.CompanyLibrarylist.forEach((item, index) => {
          item.conferenceCompanyTypeName =
            item.conferenceCompanyTypeName.split(",");
        });
        this.total = res.data.data.count;
      });
    },
    handleSizeChange(e) {
      this.currentPage = e;
      let form = {
        pageIndex: this.currentPage,
        pageSize: 10,
        ConferenceId: 7,
        TypeId: this.typeId,
      };
      getDTConferenceCompanyList(form).then((res) => {
        this.CompanyLibrarylist = res.data.data.form;
        this.total = res.data.data.count;
      });
    },
    async start() {
      this.getMyCompanyList();

      let form = {
        pageIndex: this.currentPage,
        pageSize: 10,
        ConferenceId: 7,
        TypeId: this.typeId,
      };
      getDTConferenceCompanyList(form).then((res) => {
        console.log("企业列表", res);
        this.CompanyLibrarylist = res.data.data.form;
        this.total = res.data.data.count;
        const ids = this.myCompanyMyList.map((i) => i.companyId);
        console.log(ids);
        this.CompanyLibrarylist.forEach((aitem) => {
          if (ids.includes(aitem.companyId)) {
            aitem.isFav = true;
          } else {
            aitem.isFav = false;
          }
        });
        console.log(this.CompanyLibrarylist);
      });
    },
    getHotCompanyList() {
      let forms = {
        pageIndex: 1,
         // pageIndex: Math.floor(Math.random() * 10),
        pageSize: 5,
      };
      getDTConferenceCompanyList(forms).then((res) => {
        console.log("推荐企业列表", res);
        this.hotdemandlist = res.data.data.form;
        // this.hotdemandlist.lableArr =this.hotdemandlist.conferenceCompanyTypeName.split(",")

      
      });
    },
  },
  mounted() {
    this.start();
    this.getgrouplist();
    this.getCompanyTypeZzlx();
    this.getCompanyTypeGslx();
    this.getCompanyTypeGsxz();
    this.getCompanyTypeSfdq();
    this.getHotCompanyList();
  },
};
</script>
    
<style lang="less" scoped>
.companylibrary {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
  .companylibrary-left {
    width: 100%;
    margin-right: 20px;
    .companylibrary-left-header {
      padding: 20px;
      background: white;
      .item-box {
        display: flex;
        margin-bottom: 10px;
        .header-title {
          padding-top: 8px;
          font-size: 15px;
          // padding-bottom: 26px;
          color: #adaca9;
          width: 100px;
          // font-weight: bold;
        }
        .items-main {
          display: flex;
          flex-wrap: wrap;
          width: 80%;
          div {
            padding: 8px 15px;
            // margin-right: 12px;
            // margin-bottom: 15px;
            font-size: 14px;
            cursor: pointer;
            color: #333333;
            font-weight: 400;
            &:hover {
              //  color: #0966a9;
              cursor: pointer;
              //  font-weight: bold;
            }
          }

          .active-box {
            background: #0966a9;
            color: #f5f6f6;
            cursor: pointer;
            //  font-weight: bold;
            border-radius: 3px;
          }
        }
        .open {
          width: 60px;
          margin-top: 7px;
          cursor: pointer;
          color: #9999a6;
        }
      }
    }
    .message-totle {
      padding: 20px;
      font-size: 14px;
      color: #989898;
      text-align: right;
    }
    .Company-details-item {
      padding: 20px;
      border-bottom: 1px solid #e9e9e9;
      background: white;
      display: flex;
      align-items: center;
      cursor: pointer;
      .company-left {
        img {
          width: 100px;
        }
      }
      .company-right {
        width: 100%;
        margin-left: 30px;
        .company_name {
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          .compant_shoucang {
            display: flex;
    align-items: center;
            border: 0.0625rem solid #ededed;
            padding: 0.6rem 1.2rem;
            background: #fff;
            color: #525252;
            font-size: 0.875rem;
            font-weight: 400;
            margin: auto;
          }
          div {
            // padding: 10px 25px;
            // background: #fff;
            color: #525252;
            font-size: 14px;
            margin-right: 20px;
            // border: 1px solid #ededed;
            display: flex;
            align-items: flex-end;
            span {
              margin-left: 10px;
              min-width: 44px;
            }
          }
        }
        p:nth-child(1) {
          font-size: 20px;
          margin: 0;
          margin-bottom: 10px;
        }
        p:nth-child(4) {
          margin-top: 10px;
          font-size: 12px;
          margin-bottom: 10px;
        }
        p:nth-child(5) {
          margin-top: 10px;
          font-size: 12px;
          margin-bottom: 10px;
        }
        p:hover {
          // text-decoration: underline;
          color: #0966a9;
        }
        .type-box {
          display: flex;
          font-size: 12px;
          .type {
            background: #0966a9;
            padding: 3px 10px;
            text-align: center;
            color: white;
            line-height: 20px;
          }
          .type-item {
            padding: 3px 10px;
            line-height: 20px;
            text-align: center;
            background: white;
            color: #0966a9;
            border: 1px solid #0966a9;
            margin-left: 10px;
          }
        }
        .Sub-box {
          display: flex;
          font-size: 12px;
          margin-top: 10px;
          .Sub-label {
            
            padding: 3px 10px;
            display: -webkit-box;
            line-height: 20px;
            text-align: center;
            background: #e2e2e2;
            margin-right: 15px;
            overflow:hidden; 
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;  
          }
        }
      }
    }
    .Company-details-item:hover {
      cursor: pointer;
      background: #fafafa;
    }
  }
  .companylibrary-right {
    width: 40%;
    .hot-demand {
      background: white;
      .hot-demand-header {
        padding: 16px;
        font-size: 16px;
        border-bottom: 1px solid #e9e9e9;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
      .hot-demand-item {
        padding: 20px;
        background: white;
        display: flex;
        align-items: center;
        .item-left {
          width: 100px;
          img {
            width: 100%;
          }
        }
        .item-mid {
          width: 100%;
          margin: 0 10px;
          p {
            margin: 0;
            margin-bottom: 10px;
            font-size: 16px;
          }
          .sub-box {
            display: flex;
            font-size: 0.75rem;
            cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
            // div {
            //   margin-right: 10px;
            //   font-size: 12px;
            //   color: #0966a9;
            //   padding: 4px 5px;
            //   border: 1px solid #0966a9;
            //   cursor: pointer;
            // }
          }
        }
        .item-right {
          width: 100px;
          background: white;
          text-align: center;
          padding: 6px 0px;
          font-size: 13px;
          color: #616161;
          cursor: pointer;
          // border: 1px solid #616161b2;
        }
      }
      .hot-demand-item:hover {
        cursor: pointer;
        background: #fafafa;
      }
    }
    .group-box {
      background: white;
      margin-top: 20px;
      .group-header {
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
        .see-more {
          line-height: 25px;
          padding-right: 20px;
          color: #9a9a9a;
        }
        border-bottom: 1px solid #e9e9e9;
        .header-title {
          font-size: 16px;
          color: #0b1d47;
          margin-top: 0;
          margin-left: 20px;
          margin-bottom: 20px;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 15px;
            position: relative;
            top: 5px;
          }
          span {
            font-size: 18px;
            font-weight: normal;
            color: #707070;
          }
        }
        .visiting-card {
          height: 20px;
          line-height: 20px;
          padding: 5px 15px;
          margin-top: 0;
          font-size: 14px;
          border-radius: 15px;
          margin-left: 4%;
          cursor: pointer;
        }
        .visiting-card-active {
          background: #b7d9ef65;
        }
        .wechat-group {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          padding: 5px 15px;
          border-radius: 15px;
          margin-left: 15px;
          cursor: pointer;
        }
        .wechat-group-active {
          background: linear-gradient(231deg, #2fb6b649 0%, #128f9325 100%);
        }
      }
      .group-item {
        width: 100%;
       .group-items {
          cursor: pointer;
          padding: 20px 20px;
          display: flex;
          position: relative;
          align-items: flex-end;
          .icon-a-zu337 {
            width: 20px;
            height: 20px;
            display: none;
            position: relative;
            top: 0px;
          }
          &:hover {
            padding: 20px 20px;
            background: #fafafa;
            .join {
              // background: #31448342;
              .iconfont {
                color: #0966a9;
              }
            }
            .title {
              color: #314483;
              
              // font-weight: bold;
              cursor: pointer;
            }
            // .icon-a-zu338 {
            //   display: none;
            // }
            // .icon-a-zu337 {
            //   display: block;
            // }
          }
          i {
            font-size: 20px;
          }
          .title {
            margin-left: 20px;
            margin-top: 2px;
            p {
              width: 210px;
              margin: 0;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .join {
            padding: 5px 15px;
            position: absolute;
            right: 10px;
            font-size: 14px;
            text-align: center;
            .iconfont {
              color: #cbcbcb;
              font-size: 16px;
            }
          }
        }
      }
    }
    .follow-type {
      background: white;
      margin-top: 20px;
      .follow-type-header {
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        .join-people {
          display: flex;
          div {
            font-weight: bold;
            margin-right: 20px;
            color: #9a9a9a;
            cursor: pointer;
          }
          .active-people {
            color: #262626;
          }
        }
        .cheacd-all {
          color: #005fb4;
          font-size: 14px;
        }
      }
      .follow-type-items {
        padding: 20px;
        display: flex;
        cursor: pointer;
        .items-img {
          margin-right: 10px;
          img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
          }
        }
        .user-introduce {
          width: 50%;
          margin-right: 10%;
          p {
            margin: 0;
          }
          p:nth-child(2) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 14px;
            margin-top: 7px;
          }
        }
        .add-friends {
          width: 60px;
          height: 25px;
          text-align: center;
          line-height: 25px;
          padding: 3px;
          border-radius: 5px;
          font-size: 12px;
          margin-top: 10px;
          // border: 1px solid #005fb4;
          border: 1px solid #444444;
          // color: #005fb4;
          color: #434343;
          background: white;
          cursor: pointer;
          .iconfont {
            font-weight: bold;
          }
        }
      }
      .follow-type-items:nth-child(odd) {
        background: #e9e9e961;
      }
    }
    .join-Groupchat {
      margin-top: 20px;
      background: white;
      div:nth-child(1) {
        padding: 20px;
      }
      .ewmcode-box {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .ewmcode {
          width: 100%;
          padding: 20px;
          text-align: center;
          cursor: pointer;
          position: relative;
          top: -20px;
          &:nth-child(2) {
            &:hover {
              .fkcw {
                display: block;
                img {
                  width: 100%;
                }
              }
            }
          }
          &:nth-child(1) {
            &:hover {
              .rzgsk {
                display: block;
                img {
                  width: 100%;
                }
              }
            }
          }
          .rzgsk,
          .fkcw {
            width: 120px;
            height: 120px;
            position: absolute;
            background: white;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.322);
            top: -150px;
            display: none;
            right: -58px;
          }
        }
        .ewmcode:nth-child(1) {
          border-right: 1px solid #e9e9e9;
        }
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #0966a9;
    }
    /deep/ li:hover {
      color: white;
      background: #0966a9;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #0966a9;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>